import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp-gdpr"

function NewsletterForm({ language }) {
  const [fields, setFields] = useState({
    email: "",
    "gdpr[1825]": "N",
  })
  const [state, setState] = useState({ submitting: false })

  const handleChange = (event) => {
    const target = event.target
    const name = target.name
    let value = target.value
    if (target.type === "checkbox" && !target.checked) {
      value = "N"
    }

    setFields({
      ...fields,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setState({
      ...state,
      submitting: true,
    })

    addToMailchimp(fields.email, fields)
      .then((response) => {
        // console.log(response)
        setState({
          ...state,
          submitting: false,
          response,
        })

        if (response.result !== "success") {
          throw response.msg
        }
      })
      .catch((err) => {
        console.error("mailchimp error", err)
      })
  }

  return state?.response?.result === "success" ? (
    <p>{state.response?.msg}</p>
  ) : (
    <form onSubmit={handleSubmit} disabled={state?.submitting}>
      <div class="mc-field-group">
        <label htmlFor="email">
          {language.slug === "de" ? <>E-Mail Adresse</> : <>Email address</>}
        </label>
        <input
          id="email"
          type="email"
          name="email"
          required
          onChange={handleChange}
        />
      </div>

      <div
        id="mergeRow-gdpr"
        class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
      >
        <div class="content__gdpr">
          <fieldset
            class="mc_fieldset gdprRequired mc-field-group"
            name="interestgroup_field"
          >
            <span>
              {language.slug === "de" ? (
                <>Bitte wählen Sie aus, wie Sie von uns hören möchten:</>
              ) : (
                <>Please choose how you would like to hear from us:</>
              )}
            </span>
            <div>
              <input
                type="checkbox"
                id="gdpr_1825"
                name="gdpr[1825]"
                class="gdpr"
                value="Y"
                checked
                required
                onChange={handleChange}
              />
              <label class="checkbox subfield" for="gdpr_1825">
                <span>Email</span>
              </label>
            </div>
          </fieldset>
          <p>
            <br />
            {language.slug === "de" ? (
              <>
                Sie können sich jederzeit abmelden, indem Sie auf den Link in
                der Fußzeile unserer E-Mails klicken.
              </>
            ) : (
              <>
                You can unsubscribe at any time by clicking the link in the
                footer of our emails.
              </>
            )}
          </p>
        </div>
        <div class="content__gdprLegal">
          <p>
            {language.slug === "de" ? (
              <>
                Wir nutzen Mailchimp als unsere Marketingplattform. Indem Sie
                unten klicken, um sich anzumelden, bestätigen Sie, dass Ihre
                Daten zur Verarbeitung an Mailchimp übertragen werden.{" "}
                <a href="https://mailchimp.com/de/legal/terms/" target="_blank">
                  Erfahren Sie mehr
                </a>{" "}
                über die Datenschutzpraktiken von Mailchimp.
              </>
            ) : (
              <>
                We use Mailchimp as our marketing platform. By clicking below to
                subscribe, you acknowledge that your information will be
                transferred to Mailchimp for processing.{" "}
                <a href="https://mailchimp.com/legal/terms" target="_blank">
                  Learn more
                </a>{" "}
                about Mailchimp's privacy practices.
              </>
            )}
          </p>
        </div>
      </div>

      <button type="submit" name="subscribe" disabled={state.submitting}>
        {language.slug === "de" ? <>Abonnieren</> : <>Subscribe</>}
      </button>

      {state?.response?.result === "error" && (
        <div>
          <p>{state.response?.msg}</p>
        </div>
      )}
    </form>
  )
}

export default NewsletterForm
