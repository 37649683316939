import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import FeaturedMedia from "../../components/FeaturedMedia"
// import FullPageMedia from "../../components/FullPageMedia"
import ContactForm from "../../components/ContactForm"
import NewsletterForm from "../../components/NewsletterForm"
import _ from "lodash"

// import slick
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import "./slides.slick.css"

const TypePage = ({ data, location }) => {
  const { page } = data
  const {
    uri,
    title,
    content,
    featuredImage,
    excerpt,
    databaseId,
    slug,
    isFrontPage,
    sliderBlocks,
    language,
    translations,
    template,
  } = page

  const frontPage = isFrontPage || uri === "/en/" // quick fix to solve wp-graphql-polylang's lack of setting isFrontPage of translation. todo: make work for potential other languages
  const frontPageClass = frontPage ? "front-page template-full-width" : ""
  const pageTemplate = _.kebabCase(template.templateName)
  const hasContactForm = pageTemplate === "contact-form"
  const hasNewsletterForm = pageTemplate === "newsletter-form"

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    swipe: false,
    accessibility: false,
  }
  // console.log(sliderBlocks)

  return (
    <Layout
      location={location}
      language={language}
      translations={translations}
      bodyClass={`page page-slug-${slug} page-id-${databaseId} page-template-${pageTemplate} ${frontPageClass} wp-embed-responsive singular missing-post-thumbnail has-no-pagination footer-top-visible`}
    >
      <Seo title={title} description={excerpt} lang={language.slug} />

      <article
        className={`post-${databaseId} post page type-page status-publish hentry`}
        id={`post-${databaseId}`}
      >
        {!frontPage && (
          <header className="entry-header header-footer-group page-header">
            <div className="entry-header-inner section-inner medium page-header-inner">
              <h1
                className="entry-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          </header>
        )}

        {frontPage ? (
          <>
            {/* <FullPageMedia image={featuredImage} /> */}
            {sliderBlocks?.map((slider, index) => (
              <Slider
                key={index}
                {...sliderSettings}
                initialSlide={Math.floor(
                  Math.random() * Math.floor(slider.innerBlocks.length)
                )}
              >
                {slider.innerBlocks.map((slide, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: slide.originalContent }}
                  />
                ))}
              </Slider>
            ))}
          </>
        ) : (
          <>
            <FeaturedMedia image={featuredImage} />
            <div className="post-inner thin">
              <div
                className="entry-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {hasContactForm && (
                <div className="section-inner contact-form">
                  <div className="alignhalf">
                    <ContactForm />
                  </div>
                </div>
              )}
              {hasNewsletterForm && (
                <div className="section-inner newsletter-form">
                  <div className="alignhalf">
                    <NewsletterForm language={language} />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
    }
  }
`
export default TypePage